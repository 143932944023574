<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Roles" subtitle="Manage the volunteer roles that attendees can signup to." :readonly="$authorised('con/volunteers/access', 'read')">

		<app-content-head-actions-item text="Create" icon="create" v-on:click="onCreateClick" />
		<app-content-head-actions-item text="Delete" icon="delete" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no roles found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column />
				<app-table-column text="Name" />
				<app-table-column text="Eligibility" />
				<app-table-column text="Type" />
				<app-table-column align="center" text="Slots" />
				<app-table-column align="center" text="Volunteers" />
				<app-table-column />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-label text="Open" type="success" v-if="item.status === $constants.volunteers.role.status.open" />
					<app-table-cell-label text="Closed" type="danger" v-if="item.status === $constants.volunteers.role.status.closed" />
					<app-table-cell-text :text="item.name" />
					<app-table-cell-text :text="$constants.volunteers.role.eligibilityName[item.eligibility]" />
					<app-table-cell-text :text="$constants.volunteers.role.typeName[item.type]" />
					<app-table-cell-text align="center" :text="item.count.slots" v-if="item.type === $constants.volunteers.role.type.schedule" />
					<app-table-cell-text align="center" can-empty="true" v-if="item.type !== $constants.volunteers.role.type.schedule" />
					<app-table-cell-text align="center" :text="item.count.filled.toString() + ' / ' + item.count.capacity.toString()" />
					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			layout: '48px auto 120px 100px 80px 120px 24px',
			endpoint: 'convention/volunteers/roles',
			live: 'convention/volunteers/roles'
		}

	}

}

</script>

<style scoped>

</style>